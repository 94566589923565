// Basis-Einstellungen

// HTML
// Jumping page on scroll bar appearance fix
html {
  margin-left: calc(100vw - 100%);
}

// Invisible reCAPTCHA v2 Badge verstecken (https://developers.google.com/recaptcha/docs/faq, 2018-12-27)
.grecaptcha-badge {
  display: none;
}